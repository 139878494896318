import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/700.css"

import {
  ChakraProvider,
  Heading,
  HeadingProps,
  Link,
  LinkProps,
  ListItem,
  ListItemProps,
  ListProps,
  OrderedList,
  Text,
  TextProps,
  UnorderedList,
} from "@chakra-ui/react"
import { MDXProvider } from "@mdx-js/react"
import theme from "@theme/chakra/theme"
import type { AppProps } from "next/app"
import Head from "next/head"
import NextLink from "next/link"
import { DefaultSeo } from "next-seo"

function MyApp({ Component, pageProps }: AppProps) {
  const mdx_components = {
    h1: (props: HeadingProps) => (
      <Heading
        as={"h1"}
        fontSize={"2xl"}
        {...props}
      />
    ),
    h2: (props: HeadingProps) => (
      <Heading
        as={"h2"}
        fontSize={"xl"}
        {...props}
      />
    ),
    h3: (props: HeadingProps) => (
      <Heading
        as={"h3"}
        fontSize={"lg"}
        fontWeight={"500"}
        {...props}
      />
    ),
    ul: (props: ListProps) => <UnorderedList {...props} />,
    ol: (props: ListProps) => <OrderedList {...props} />,
    li: (props: ListItemProps) => (
      <ListItem
        mt={2}
        ms={4}
        {...props}
      />
    ),
    p: (props: TextProps) => (
      <Text
        as={"p"}
        marginTop={"4"}
        fontSize={"lg"}
        {...props}
      />
    ),
    a: (props: LinkProps) => (
      <Link
        as={NextLink}
        color={"brand.600"}
        textDecoration={"underline"}
        textDecorationStyle={"dotted"}
        textUnderlineOffset={"0.3em"}
        _hover={{
          textDecoration: "none",
        }}
        {...props}
      />
    ),
  }

  return (
    <MDXProvider components={mdx_components}>
      <ChakraProvider theme={theme}>
        <Head>
          <meta
            name={"viewport"}
            content={
              "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=2,user-scalable=yes"
            }
          />
        </Head>
        <DefaultSeo
          defaultTitle={
            "White Lion General Trading LLC - Leading Book Distributor in the UAE"
          }
          titleTemplate={"%s | White Lion General Trading LLC"}
          description={
            "Discover a wide range of high-quality educational books and resources at White Lion General Trading LLC. As the leading book distributor in the UAE, we offer an extensive collection of academic materials, personalized curation services, and exceptional customer support. Partner with us to enhance your institution's library resources and provide your students with the best educational materials available. Visit our website now."
          }
          openGraph={{
            type: "website",
            locale: "en_AE",
            url: "https://www.whitelion.ae/",
            siteName: "White Lion General Trading LLC",
            images: [
              {
                url: `https://www.whitelion.ae/images/og-whitelion.png`,
                width: 1200,
                height: 630,
                alt: "White Lion General Trading LLC - Leading Book Distributor in the UAE",
              },
            ],
          }}
        />
        <Component {...pageProps} />
      </ChakraProvider>
    </MDXProvider>
  )
}

export default MyApp
