// eslint-disable-next-line import/no-anonymous-default-export
export default {
  black: "#1c1c1c",
  brand: {
    "50": "#c2baa6",
    "100": "#b5ac94",
    "200": "#a99e83",
    "300": "#9d9071",
    "400": "#91825f",
    "500": "#85754e",
    "600": "#776946",
    "700": "#6a5d3e",
    "800": "#5d5136",
    "900": "#4f462e",
  },
}
